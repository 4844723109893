import React from 'react';
import SocialLink from '../../molecules/SocialLink/SocialLink';
import {
  Section,
  OuterWrapper,
  InnerWrapper,
  Header,
  Grid,
} from './styles/SocialMediaCalloutComponent.styled';

const SocialMediaComponent = ({ component }) => {
  const { id, headingKicker, heading, subhead, socialLink } = component;

  return (
    <Section id={id}>
      <OuterWrapper>
        <InnerWrapper>
          <Header>
            {headingKicker && <p className="heading-kicker">{headingKicker}</p>}
            {heading && <h2 className="heading">{heading}</h2>}
            {subhead && <p className="subhead">{subhead}</p>}
          </Header>
          {socialLink && (
            <Grid>
              {socialLink.map(item => (
                <SocialLink key={item.id} component={item} />
              ))}
            </Grid>
          )}
        </InnerWrapper>
      </OuterWrapper>
    </Section>
  );
};

export default SocialMediaComponent;
